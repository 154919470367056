@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
    html { overflow: hidden; }
    .noPrint{
        display: none;
    }
    .fila{
        break-inside: avoid;
    }
  }